@import "../../colors.scss";

section.abc-section {
  padding: 20px 0px;
  border-bottom: 1px solid $dividing-grey;
  width: 100%;
  display: inline-block;

  h2 {
    font-weight: normal;
    font-size: 20px;
  }
}
