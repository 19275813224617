$abc-orange: #ea592c;
$dirty-pink: rgba(251, 246, 246, 0.8);
$solid-dirty-pink: rgba(251, 246, 246, 1);
$dirtier-pink: rgba(245, 239, 239, 0.8);
$dark-grey: #333333;
$light-grey: rgba(0, 0, 0, 0.4);
$lighter-grey: rgba(0, 0, 0, 0.2);
$dividing-grey: #f1f3f4;
$white: #fff;
$shadow: rgba(51, 51, 51, 0.16);
$black: #000;
$trans-white: rgba(255, 255, 255, 0.8);
$red: red;
$warning-red: rgba(255, 0, 0, 0.1);
$warning-yellow: rgba(255, 204, 0, 1);
$green: green;
$highlight-green: rgba(0, 255, 0, 0.1);
$changed-new: rgba(0, 255, 0, 1.0);
$changed-moved: rgba(0, 0, 255, 1.0);
$changed-removed: rgba(255, 0, 0, 1.0);

