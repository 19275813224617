@import "../../colors.scss";

.vehicles-list {
  .vehicle {
    border-bottom: 1px solid $dividing-grey;
    padding: 35px 0;
    cursor: pointer;

    .main-text {
      margin-bottom: 5px;
    }
  }
}

.view-vehicle,
.new-vehicle {
  margin-top: 85px;
}

.autocomplete-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  font-size: 14px;

  .icon {
    display: inline-flex;
    align-items: center;
  }

  .sub-text {
    color: $light-grey;
  }
}

.autocomplete-item.no-subtext {
    min-height: 24px;
}

@media (min-width: 1280px) {
  .app .page {
    margin-top: 0px;
  }
}
