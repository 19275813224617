@import '../../colors.scss';

.no-records {
    border-bottom: 1px solid $dividing-grey;
    font-size: 12px;
    color: $light-grey;
    padding: 40px 0;

    .message {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}