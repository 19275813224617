@import "../../colors.scss";

.unavailability-section {
  .unavailability {
    padding: 20px 0;

    .more {
      text-align: center;
    }

    .main-text {
      margin-bottom: 5px;
    }
  }

  .link-button {
    color: $abc-orange;
    margin: 20px 0;
    display: block;
  }
}
