@import "./colors.scss";

body {
  font-family: Roboto;
  margin: 0;
}

.app {
  .action {
    cursor: pointer;
  }

  .page {
    margin-top: 84px;
  }

  .error {
    color: $red;
  }

  .warning {
    color: $warning-red;
  }
}

.sub-text {
  font-size: 14px;
  color: $light-grey;
}

.form {
    /* Ensure there is always room for the actions menu */
    padding-bottom: 105px;
}

.grab {
    cursor: grab;
}

.move {
    cursor: move;
}

.pointer {
    cursor: pointer;
}

.inline-block {
    display: inline-block;
}

@media (min-width: 1280px) {
  .app {
    .main-container {
      margin-left: 235px;
    }
  }
}
