@import "../../colors.scss";

.context-menu-host {
    position: relative;
    display: inline-block;
    z-index: 50;
}

.icon-container {
    text-align: center;
}

.context-menu {
    background-color: #ffffff;
    background-size: cover;
    border-radius: 16px;
    box-shadow: 0px 8px 16px rgba(51,51,51,0.08), 0px 16px 32px rgba(51,51,51,0.16);
    padding: 20px 30px;
    position: absolute;
    text-align: left;
    top: 0px;
    width: 300px;
}

.context-menu {
    h1 {
        height: 30px;
        font-size: 12px;
        color: #333333;
        font-weight: 300;
        line-height: 30px;
    }

    .context-action {
        cursor: pointer;
        height: 40px;
        font-size: 18px;
        color: #333333;
        font-weight: 400;
        line-height: 40px;
    }

    .context-action:hover {
        color: $abc-orange;
    }

    ul {
        margin: 0;
        padding: 0;
        li {
            list-style-type: none;
        }
    }
}
