@import '../../colors.scss';

.loading-panel {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    .main {
        position: fixed;
        z-index: 102;
    }
}

.loader {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);

    svg {
        color: $abc-orange;
    }
}