@import "../../colors.scss";

.jobs {
    .section-header {
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: #f5efef;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        padding: 30px 0;
    }
    .jobs-container {
        margin-top: 130px;
    }
    .unschedulable {
        color: red;
    }
}

.job-list {
    width: 100%;

    .action.disabled {
        color: $light-grey;
    }

    .name-container {
        min-width: 800px;
        width: 84%;
        height: 24px;
        font-family: Roboto;
        font-size: 18px;
        color: #333333;
        font-weight: 300;
        text-decoration: none solid rgb(51, 51, 51);
        line-height: 24px;
        margin-left: 30px;
        padding: 40px 0;
        margin-bottom: 10px;
    }

    .job {
        border-bottom: 1px solid $dividing-grey;
        padding: 30px 0;
        cursor: pointer;

        .headers,
        .detail {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }

        .headers {
            font-size: 14px;
            color: $light-grey;
            line-height: 24px;
        }

        .unschedulable {
            color: red;
        }
    
        .detail {
            font-size: 16px;
            color: $dark-grey;
        }

        .address,
        .customer {
            padding-right: 10px;
            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 95%;
                display: inline-block;
                margin: 0px;
            }
        }
      
        .status-icons {
            text-align: left;
        }

        .warning {
            color: $red;
            font-weight: 500
        }

        .warning-icon {
            color: $warning-yellow;
        }
    }
}

.filter-state {
    width: 100%;
    background: $trans-white;
    position: fixed;
    background-size: cover;
    z-index: 99;
    white-space: nowrap;
    border-bottom: 1px solid $dividing-grey;
    height: 50px;
    line-height: 45px;
    top: 84px;

    .chip {
        background: $dirtier-pink;
        margin-right: 5px;

        svg {
            color: $abc-orange;
        }
    }
}

.job-form {
    .job-link {
        color: $abc-orange;
        cursor: pointer;
        line-height: 26px;
    }

    .job-order-item {
        width: 100%;
    }

    .job-input-field input {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .detail-and-icon {
        display: flex;
        span {
            line-height: 26px;
            margin-left: 5px;
        }
    }

    .event-items {
        display: block;
        width: 100%;
    }

    .event-item {
        border-top: 1px solid #f1f3f4;
        font-size: 12px;
        color: rgba(0,0,0,0.4);
        padding: 20px 0;
        width: 100%;
        display: inline-block;
    }

    .image {
        width: 85px;
        height: 85px;
        border-radius: 16px;
        background-size: cover;
    }

    .imageContainer {    
        box-sizing: border-box;
        float: left;
        overflow: hidden;
        margin-bottom: 5px;
        position: relative; 
        max-height: 85px;
        max-width: 85px;
        margin-right: 10px;
        cursor: pointer;
        
        :hover {
            opacity: 0.9;
        }
    }

    .signatureContainer {    
        box-sizing: border-box;
        float: left;
        overflow: hidden;
        margin-bottom: 5px;
        position: relative; 
        max-height: 85px;
        max-width: 90%;
        
        :hover {
            opacity: 0.9;
        }
    }

    .signature {
        max-width: 90%;
        max-height: 85px;
        background-size: cover;
    }

    .gallery {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
        white-space: nowrap;
    }

    .photoTitle{
        width: 518px;
        font-family: Roboto;
        font-size: 12px;
        color: #333333;
        font-weight: 300;
        text-decoration: none solid rgba(51, 51, 51, 0.5);
    }
}

@media (min-width: 1280px) {
  .filter-state .chips {
    margin-left: -22px;
  }
}
