@import '../../colors.scss';

.notification.info div {
    background-color: $abc-orange;
}

.notification.error div {
    background-color: $black;
}

.notification .message-content {
    margin: -1em 0;
}