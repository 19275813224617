@import "../../colors.scss";

.actions-menu {
    .icon-container {
        height: 56px;
        width: 56px;
        border-radius: 16px 0 16px 0;
        background-color: $abc-orange;
        box-shadow: 0 4px 8px 0 $shadow, 0 8px 16px 0 $shadow, 0 26px 32px 0 $shadow;
        cursor: pointer;
        position: fixed;
        right: 15px;
        bottom: 15px;
        text-align: center;

        transition: bottom 0.6s;
        z-index: 98;

        &.active .icon {
            transform: rotate(90deg);
        }

        .icon {
            color: $white;
            margin: 15px;
        }
    }

    .actions {
        position: fixed;
        bottom: -400px;
        width: 100%;
        border-radius: 16px 0 0 0;
        background-color: $dirty-pink;
        box-shadow: 0 0 8px 0 $shadow, 0 0 16px 0 $shadow, 0 0 32px 0 $shadow;
        transition: bottom 0.6s;
        color: $abc-orange;
        z-index: 1;

        &.active {
            bottom: 0px;
        }

        .items {
        padding: 35px 0px;
        }

        .icons-col {
            margin-left: 12px;
            position: relative;
            top: 6px;
        }

        .action-item {
        .action {
            height: 35px;
            display: block;

            .action-label {
                width: 128px;
                }
            }
        }
        .text-right {
            text-align: right;
        }
        .action.disabled {
            color: $light-grey;
            cursor: not-allowed;
        }
    }
}

.action-container {
    height: 56px;
    width: 56px;
    border-radius: 16px 0 16px 0;
    background-color: $abc-orange;
    box-shadow: 0 4px 8px 0 $shadow, 0 8px 16px 0 $shadow, 0 26px 32px 0 $shadow;
    position: fixed;
    right: 15px;
    bottom: 15px;
    text-align: center;

    .icon {
        color: $white;
        margin: 15px;
    }
}

@media (min-width: 1280px) {
    .actions-menu .actions {
        width: calc(100% - 235px);
    }
}
