@import '../../colors.scss';

.header {
  width: 100%;
  background: $dirty-pink;
  position: fixed;
  top: 0px;
  background-size: cover;
  z-index: 99;
  height: 84px;
  padding-top: 32px;
  white-space: nowrap;

  h2 {
    display: inline-block;
    margin: 0;
    font-size: 24px;
    color: $dark-grey;
    line-height: 24px;
    font-weight: normal;
  }

  .center {
    text-align: center;
  }
}

.header-nav {
  width: 100%;
  background: $dirtier-pink;
  position: fixed;
  top: 84px;
  z-index: 2;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;

  .search {
    display: none;
  }
}

@media (min-width: 1280px) {
  .header {
    margin-left: -20px;

    &.hidden {
      visibility: hidden;
      height: 0px;
    }

    .menu {
      visibility: hidden;
    }
  }

  .header-nav {
    ul {
      margin-left: -22px;
    }

    &.as-title {
      top: 0px;
      height: 84px;
      background: $dirty-pink;
      font-size: 24px;
      ul {
        padding: 0;
        padding-top: 25px;
        margin-left: -22px;

        li a {
          font-size: 24px;
        }
      }
    }

    .search {
      display: inline-block;
      text-align: left;
      padding-top: 45px;
    }
  }
}
