@import "../../colors.scss";

.drivers-list {
  .driver {
    border-bottom: 1px solid $dividing-grey;
    padding: 35px 0;
    cursor: pointer;

    .main-text {
      margin-bottom: 5px;
    }

    .status-icons {
      text-align: right;
    }
  }
}

.view-driver,
.new-driver {
  margin-top: 85px;
}

@media (min-width: 1280px) {
  .app .page {
    margin-top: 0px;
  }
}
