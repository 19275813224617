.environment {
    width: 300px;
    position: fixed;
    z-index: 100;
    left: 70%;
    transform: translateX(-50%);
    padding: 5px;
    top: 0;
    text-align: center;
    border-radius: 0 0 10px 10px;
    font-weight: bold;
    background-color: red;
    color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.08), 0 8px 16px 0 rgba(0,0,0,0.08), 0 26px 32px 0 rgba(0,0,0,0.08);
}
