body {
  font-family: Roboto;
  margin: 0; }

.app .action {
  cursor: pointer; }

.app .page {
  margin-top: 84px; }

.app .error {
  color: red; }

.app .warning {
  color: rgba(255, 0, 0, 0.1); }

.sub-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4); }

.form {
  /* Ensure there is always room for the actions menu */
  padding-bottom: 105px; }

.grab {
  cursor: -webkit-grab;
  cursor: grab; }

.move {
  cursor: move; }

.pointer {
  cursor: pointer; }

.inline-block {
  display: inline-block; }

@media (min-width: 1280px) {
  .app .main-container {
    margin-left: 235px; } }

.jobs .section-header {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #f5efef;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  padding: 30px 0; }

.jobs .jobs-container {
  margin-top: 130px; }

.jobs .unschedulable {
  color: red; }

.job-list {
  width: 100%; }
  .job-list .action.disabled {
    color: rgba(0, 0, 0, 0.4); }
  .job-list .name-container {
    min-width: 800px;
    width: 84%;
    height: 24px;
    font-family: Roboto;
    font-size: 18px;
    color: #333333;
    font-weight: 300;
    -webkit-text-decoration: none solid #333333;
            text-decoration: none solid #333333;
    line-height: 24px;
    margin-left: 30px;
    padding: 40px 0;
    margin-bottom: 10px; }
  .job-list .job {
    border-bottom: 1px solid #f1f3f4;
    padding: 30px 0;
    cursor: pointer; }
    .job-list .job .headers,
    .job-list .job .detail {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%; }
    .job-list .job .headers {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
      line-height: 24px; }
    .job-list .job .unschedulable {
      color: red; }
    .job-list .job .detail {
      font-size: 16px;
      color: #333333; }
    .job-list .job .address,
    .job-list .job .customer {
      padding-right: 10px; }
      .job-list .job .address span,
      .job-list .job .customer span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 95%;
        display: inline-block;
        margin: 0px; }
    .job-list .job .status-icons {
      text-align: left; }
    .job-list .job .warning {
      color: red;
      font-weight: 500; }
    .job-list .job .warning-icon {
      color: #ffcc00; }

.filter-state {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  background-size: cover;
  z-index: 99;
  white-space: nowrap;
  border-bottom: 1px solid #f1f3f4;
  height: 50px;
  line-height: 45px;
  top: 84px; }
  .filter-state .chip {
    background: rgba(245, 239, 239, 0.8);
    margin-right: 5px; }
    .filter-state .chip svg {
      color: #ea592c; }

.job-form .job-link {
  color: #ea592c;
  cursor: pointer;
  line-height: 26px; }

.job-form .job-order-item {
  width: 100%; }

.job-form .job-input-field input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.job-form .detail-and-icon {
  display: flex; }
  .job-form .detail-and-icon span {
    line-height: 26px;
    margin-left: 5px; }

.job-form .event-items {
  display: block;
  width: 100%; }

.job-form .event-item {
  border-top: 1px solid #f1f3f4;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  padding: 20px 0;
  width: 100%;
  display: inline-block; }

.job-form .image {
  width: 85px;
  height: 85px;
  border-radius: 16px;
  background-size: cover; }

.job-form .imageContainer {
  box-sizing: border-box;
  float: left;
  overflow: hidden;
  margin-bottom: 5px;
  position: relative;
  max-height: 85px;
  max-width: 85px;
  margin-right: 10px;
  cursor: pointer; }
  .job-form .imageContainer :hover {
    opacity: 0.9; }

.job-form .signatureContainer {
  box-sizing: border-box;
  float: left;
  overflow: hidden;
  margin-bottom: 5px;
  position: relative;
  max-height: 85px;
  max-width: 90%; }
  .job-form .signatureContainer :hover {
    opacity: 0.9; }

.job-form .signature {
  max-width: 90%;
  max-height: 85px;
  background-size: cover; }

.job-form .gallery {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  white-space: nowrap; }

.job-form .photoTitle {
  width: 518px;
  font-family: Roboto;
  font-size: 12px;
  color: #333333;
  font-weight: 300;
  -webkit-text-decoration: none solid rgba(51, 51, 51, 0.5);
          text-decoration: none solid rgba(51, 51, 51, 0.5); }

@media (min-width: 1280px) {
  .filter-state .chips {
    margin-left: -22px; } }

.context-menu-host {
  position: relative;
  display: inline-block;
  z-index: 50; }

.icon-container {
  text-align: center; }

.context-menu {
  background-color: #ffffff;
  background-size: cover;
  border-radius: 16px;
  box-shadow: 0px 8px 16px rgba(51, 51, 51, 0.08), 0px 16px 32px rgba(51, 51, 51, 0.16);
  padding: 20px 30px;
  position: absolute;
  text-align: left;
  top: 0px;
  width: 300px; }

.context-menu h1 {
  height: 30px;
  font-size: 12px;
  color: #333333;
  font-weight: 300;
  line-height: 30px; }

.context-menu .context-action {
  cursor: pointer;
  height: 40px;
  font-size: 18px;
  color: #333333;
  font-weight: 400;
  line-height: 40px; }

.context-menu .context-action:hover {
  color: #ea592c; }

.context-menu ul {
  margin: 0;
  padding: 0; }
  .context-menu ul li {
    list-style-type: none; }

.no-records {
  border-bottom: 1px solid #f1f3f4;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  padding: 40px 0; }
  .no-records .message {
    padding-top: 10px;
    padding-bottom: 10px; }

.dialog {
  height: 100%;
  border-radius: 0px;
  background-color: #fff;
  border-radius: 16px 0px 0px;
  -webkit-transition: right 0.6s;
  transition: right 0.6s;
  z-index: 101;
  position: fixed;
  top: 0px;
  right: -500px; }
  .dialog .content {
    text-align: left;
    margin-top: 30px;
    line-height: 24px; }
  .dialog .head {
    padding: 32px 0;
    text-align: left;
    border-bottom: 1px solid #f1f3f4; }
    .dialog .head h2 {
      display: inline-block;
      margin: 0;
      font-size: 24px;
      color: #333333;
      line-height: 24px;
      font-weight: normal;
      text-align: left; }
  .dialog .arrow-container .arrow {
    display: block;
    margin: 32px auto; }
  .dialog.slide {
    height: 100%;
    width: 0px;
    background-color: #fff;
    -webkit-transition: right 0.6s;
    transition: right 0.6s;
    z-index: 100;
    position: fixed;
    top: 0px;
    right: -500px; }
  .dialog.full-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 101;
    background-color: #fbf6f6;
    top: 0;
    left: 0;
    overflow-y: scroll;
    border-radius: 0px; }
    .dialog.full-screen .content,
    .dialog.full-screen .head,
    .dialog.full-screen .actions {
      max-width: 480px;
      margin: 0 auto; }
    .dialog.full-screen .arrow-container .arrow {
      display: none; }
  .dialog.active {
    right: 0px;
    width: 100%; }
  .dialog .actions {
    color: rgba(0, 0, 0, 0.4);
    padding-top: 50px;
    text-align: right; }
    .dialog .actions div {
      margin-left: 20px;
      display: inline-flex;
      vertical-align: middle;
      align-items: center; }
    .dialog .actions .icon svg {
      vertical-align: middle; }

@media (min-width: 1280px) {
  .dialog {
    box-shadow: rgba(51, 51, 51, 0.16) 0px 0px 16px 0px; }
    .dialog.slide.active {
      right: 0px;
      width: 500px; }
    .dialog.slide .arrow-container .arrow {
      display: none; } }

.loading-panel {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0; }
  .loading-panel .main {
    position: fixed;
    z-index: 102; }

.loader {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px); }
  .loader svg {
    color: #ea592c; }

.header {
  width: 100%;
  background: rgba(251, 246, 246, 0.8);
  position: fixed;
  top: 0px;
  background-size: cover;
  z-index: 99;
  height: 84px;
  padding-top: 32px;
  white-space: nowrap; }
  .header h2 {
    display: inline-block;
    margin: 0;
    font-size: 24px;
    color: #333333;
    line-height: 24px;
    font-weight: normal; }
  .header .center {
    text-align: center; }

.header-nav {
  width: 100%;
  background: rgba(245, 239, 239, 0.8);
  position: fixed;
  top: 84px;
  z-index: 2;
  height: 40px;
  line-height: 40px;
  white-space: nowrap; }
  .header-nav .search {
    display: none; }

@media (min-width: 1280px) {
  .header {
    margin-left: -20px; }
    .header.hidden {
      visibility: hidden;
      height: 0px; }
    .header .menu {
      visibility: hidden; }
  .header-nav ul {
    margin-left: -22px; }
  .header-nav.as-title {
    top: 0px;
    height: 84px;
    background: rgba(251, 246, 246, 0.8);
    font-size: 24px; }
    .header-nav.as-title ul {
      padding: 0;
      padding-top: 25px;
      margin-left: -22px; }
      .header-nav.as-title ul li a {
        font-size: 24px; }
  .header-nav .search {
    display: inline-block;
    text-align: left;
    padding-top: 45px; } }

.schedule .icon-action {
  cursor: pointer; }

.schedule .inline-block {
  display: inline-block; }

.schedule .schedule-header {
  margin-left: 0;
  position: fixed;
  top: 84px;
  width: 100%;
  z-index: 2; }
  .schedule .schedule-header .schedule-date-select {
    width: 100%;
    background: rgba(245, 239, 239, 0.8);
    line-height: 40px;
    padding-top: 20px;
    white-space: nowrap; }
    .schedule .schedule-header .schedule-date-select ul li a {
      font-size: 24px; }
  .schedule .schedule-header .schedule-version-select {
    background: rgba(245, 239, 239, 0.8);
    height: 40px; }
  .schedule .schedule-header .date-nav {
    width: 100%;
    background: rgba(245, 239, 239, 0.8);
    top: 124px;
    height: 29px;
    line-height: 40px;
    white-space: nowrap; }
  .schedule .schedule-header ul {
    padding: 0;
    margin: 0;
    line-height: 40px; }
    .schedule .schedule-header ul li {
      display: inline-block;
      margin-right: 20px;
      line-height: 20px; }
      .schedule .schedule-header ul li a {
        font-size: 16px;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.4);
        text-decoration: none;
        cursor: pointer; }
        .schedule .schedule-header ul li a.active {
          color: #ea592c; }
  .schedule .schedule-header .date-container {
    line-height: 40px;
    min-width: 250px; }
  .schedule .schedule-header .date-input {
    margin-top: -5px;
    width: 250px; }

.schedule .section-header {
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #f5efef;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  padding: 30px 0; }

.schedule .no-schedule,
.schedule .live {
  width: 100%;
  margin-top: 225px; }

.schedule .no-schedule-header {
  width: 790px;
  height: 24px;
  font-family: Roboto;
  font-size: 24px;
  color: #333333;
  -webkit-text-decoration: none solid #333333;
          text-decoration: none solid #333333;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px; }

.schedule .no-schedule-content {
  width: 518px;
  height: 48px;
  font-family: Roboto;
  font-size: 20px;
  color: #333333;
  font-weight: 300;
  -webkit-text-decoration: none solid #333333;
          text-decoration: none solid #333333;
  line-height: 24px;
  text-align: center; }

.schedule .center {
  text-align: center; }

.schedule .no-schedule-image {
  width: 400px;
  height: 400px; }

.schedule .run-container.dual {
  right: 240px; }

.schedule .run-container {
  width: 219px;
  height: 56px;
  border-top-left-radius: 16px;
  background-color: #ea592c;
  background-size: cover;
  box-shadow: 0px 8px 16px rgba(51, 51, 51, 0.08), 0px 16px 32px rgba(51, 51, 51, 0.16);
  position: fixed;
  right: 15px;
  bottom: 0px;
  text-align: center;
  align-items: center;
  cursor: pointer; }

.schedule .run-text {
  width: 148px;
  height: 24px;
  font-family: Roboto;
  font-size: 18px;
  color: #fff;
  -webkit-text-decoration: none solid white;
          text-decoration: none solid white;
  line-height: 24px;
  text-transform: uppercase;
  padding-left: 31px; }

.schedule .run-icon {
  width: 24px;
  height: 24px;
  fill: #fcfcfc;
  padding: 16px; }

.schedule .badge span {
  top: 50%;
  background-color: #ea592c; }

.schedule .badge {
  padding-right: 16px;
  vertical-align: baseline; }

.schedule .alert-text {
  width: 100%;
  background: #ea592c;
  position: fixed;
  top: 154px;
  z-index: 2;
  height: 40px;
  line-height: 40px;
  text-align: center;
  white-space: nowrap;
  color: white;
  vertical-align: center;
  text-align: center; }

.schedule .alert-text + .schedule-container {
  margin-top: 254px; }

.schedule .schedule-container {
  margin-top: 214px; }

.schedule .active input {
  cursor: pointer; }

.schedule .disabled-li {
  cursor: not-allowed; }

.change-list,
.schedule-list,
.available-vehicles,
.unassigned-driver-list {
  width: 100%; }
  .change-list .headers,
  .change-list .detail,
  .schedule-list .headers,
  .schedule-list .detail,
  .available-vehicles .headers,
  .available-vehicles .detail,
  .unassigned-driver-list .headers,
  .unassigned-driver-list .detail {
    display: inline-block;
    margin: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
  .change-list .headers,
  .schedule-list .headers,
  .available-vehicles .headers,
  .unassigned-driver-list .headers {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    line-height: 24px; }
  .change-list .headers.warning,
  .schedule-list .headers.warning,
  .available-vehicles .headers.warning,
  .unassigned-driver-list .headers.warning {
    color: #ea592c; }
  .change-list .detail,
  .schedule-list .detail,
  .available-vehicles .detail,
  .unassigned-driver-list .detail {
    color: #333333;
    font-size: 16px; }

.available-vehicles .available-vehicle {
  border-bottom: 1px solid #f1f3f4;
  padding: 30px 0; }

.available-vehicles .section {
  width: 100%; }
  .available-vehicles .section.action-strip {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #f1f3f4; }

.change-list .item,
.unassigned-driver-list .item {
  border-bottom: 1px solid #f1f3f4;
  padding: 15px 0; }

.schedule-list .detail.address.end {
  color: red; }

.schedule-list .detail.address.start {
  color: green; }

.schedule-list .detail.warning {
  color: red;
  font-weight: 500; }

.schedule-list .outside-window .etd, .schedule-list .outside-window .time {
  color: red; }

.schedule-list .past-etd .etd {
  color: #ea592c; }

.schedule-list .past-eta .time {
  color: rgba(0, 0, 0, 0.4); }

.schedule-list .next-eta .time {
  color: green;
  font-weight: bold; }

.schedule-list .action.disabled {
  color: rgba(0, 0, 0, 0.4); }

.schedule-list .icon-container {
  text-align: center; }

.schedule-list .icon-coloured {
  color: #ea592c; }

.schedule-list .rego-container {
  padding-right: 12px; }

.schedule-list .route-header {
  color: #ea592c;
  font-weight: bold; }

.schedule-list .route-header svg {
  vertical-align: middle; }

.schedule-list .name-container.paused {
  color: rgba(255, 0, 0, 0.1); }

.schedule-list .paused .route-header {
  color: red; }

.schedule-list .schedule-list-item {
  border-bottom: 1px solid #f1f3f4;
  padding: 30px 0; }

.schedule-list .section {
  width: 100%; }
  .schedule-list .section.action-strip {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #f1f3f4; }

.schedule-list .name-container {
  min-width: 800px;
  width: 84%;
  height: 24px;
  font-family: Roboto;
  font-size: 18px;
  color: #333333;
  font-weight: 300;
  -webkit-text-decoration: none solid #333333;
          text-decoration: none solid #333333;
  line-height: 24px;
  margin-left: 16px;
  padding: 40px 0;
  margin-bottom: 20px; }

.schedule-list .taskWrapper {
  margin-left: 15px;
  border-left: 5px transparent; }

.schedule-list .taskWrapper.changed-new {
  border-left: solid lime; }

.schedule-list .taskWrapper.changed-moved {
  border-left: solid blue; }

.schedule-list .task {
  min-width: 800px;
  width: 96%;
  height: 108px;
  border: 1px solid #f5efef;
  border-radius: 16px;
  background-color: #fff;
  background-size: cover;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 4px 8px rgba(0, 0, 0, 0.04);
  padding: 40px 0;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 8%; }
  .schedule-list .task .align-center {
    text-align: center; }
  .schedule-list .task .move-task-button {
    background-color: #ea592c;
    border-radius: 8px;
    color: #fff;
    margin-left: 24px;
    padding: 16px; }
  .schedule-list .task .no-pad {
    padding-top: 0; }
  .schedule-list .task .status-icons {
    text-align: right; }
  .schedule-list .task .task-type {
    color: #ea592c; }

.schedule-list .task.firstup-task, .schedule-list .task.asap-task {
  background-color: rgba(0, 255, 0, 0.1); }

.schedule-list .task.cancelled-task {
  background: rgba(0, 0, 0, 0.2); }
  .schedule-list .task.cancelled-task .detail {
    text-decoration: line-through; }
    .schedule-list .task.cancelled-task .detail span {
      text-decoration: line-through; }
  .schedule-list .task.cancelled-task .detail.status-text {
    text-decoration: none; }

.schedule-list .task.draggable {
  background: -webkit-repeating-linear-gradient(45deg, rgba(251, 246, 246, 0.8), rgba(251, 246, 246, 0.8) 10px, rgba(245, 239, 239, 0.8) 10px, rgba(245, 239, 239, 0.8) 20px);
  background: repeating-linear-gradient(45deg, rgba(251, 246, 246, 0.8), rgba(251, 246, 246, 0.8) 10px, rgba(245, 239, 239, 0.8) 10px, rgba(245, 239, 239, 0.8) 20px); }

.schedule-list .task.dragging {
  opacity: 0; }

.schedule-list .task.invalid-task {
  background: -webkit-repeating-linear-gradient(45deg, rgba(255, 0, 0, 0.1), rgba(255, 0, 0, 0.1) 10px, rgba(255, 255, 255, 0.8) 10px, rgba(255, 255, 255, 0.8) 20px);
  background: repeating-linear-gradient(45deg, rgba(255, 0, 0, 0.1), rgba(255, 0, 0, 0.1) 10px, rgba(255, 255, 255, 0.8) 10px, rgba(255, 255, 255, 0.8) 20px); }

.schedule-list .task.complete-task {
  background: #f1f3f4;
  height: auto;
  padding: 10px 0; }
  .schedule-list .task.complete-task .done {
    text-align: center;
    color: #ea592c; }
  .schedule-list .task.complete-task .headers {
    display: none; }
  .schedule-list .task.complete-task .detail {
    color: rgba(0, 0, 0, 0.4); }
    .schedule-list .task.complete-task .detail div {
      align-self: center; }
    .schedule-list .task.complete-task .detail span {
      vertical-align: middle; }

.schedule-list .taskWrapper.changed-removed {
  border-left: solid red; }

.schedule-list .removed-message {
  min-width: 800px;
  width: 96%;
  border: 1px solid #f5efef;
  border-radius: 16px;
  background-color: #fff;
  background-size: cover;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 4px 8px rgba(0, 0, 0, 0.04);
  padding: 10px 0;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 8%; }

.live-schedule .schedule-list .task.late-task {
  background-color: rgba(255, 0, 0, 0.1); }

.draft-schedule .job.firstup-job, .draft-schedule .job.asap-job {
  background-color: rgba(0, 255, 0, 0.1); }

.highlight {
  color: #ea592c; }
  .highlight span {
    background-color: #ea592c;
    color: #fff; }

.move-task-dialog {
  background-color: #ffffff;
  background-size: cover;
  border-radius: 16px;
  box-shadow: 0px 8px 16px rgba(51, 51, 51, 0.08), 0px 16px 32px rgba(51, 51, 51, 0.16);
  padding: 20px 30px;
  position: relative;
  text-align: left;
  left: 72px;
  top: -60px;
  width: 160px;
  z-index: 50; }
  .move-task-dialog .action {
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.4); }
  .move-task-dialog .action svg {
    vertical-align: middle; }

.abc-return-dialog .radio-wrapper {
  margin-bottom: 30px; }

.schedule-to-route-dialog .checkbox-control {
  padding-top: 1.5em; }

.schedule-to-route-dialog .checkbox-icon {
  fill: #ea592c;
  padding-right: 0.5em; }

.unassign-live-job-dialog .unassign-note {
  color: rgba(0, 0, 0, 0.4); }

@media (min-width: 1280px) {
  .filter-state .chips {
    margin-left: -22px; }
  .schedule .schedule-header {
    margin-left: -20px;
    top: 0px; }
  .schedule .alert-text + .schedule-container {
    margin-top: 170px; }
  .schedule .schedule-container {
    margin-top: 130px; } }

.actions-menu .icon-container {
  height: 56px;
  width: 56px;
  border-radius: 16px 0 16px 0;
  background-color: #ea592c;
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.16), 0 8px 16px 0 rgba(51, 51, 51, 0.16), 0 26px 32px 0 rgba(51, 51, 51, 0.16);
  cursor: pointer;
  position: fixed;
  right: 15px;
  bottom: 15px;
  text-align: center;
  -webkit-transition: bottom 0.6s;
  transition: bottom 0.6s;
  z-index: 98; }
  .actions-menu .icon-container.active .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .actions-menu .icon-container .icon {
    color: #fff;
    margin: 15px; }

.actions-menu .actions {
  position: fixed;
  bottom: -400px;
  width: 100%;
  border-radius: 16px 0 0 0;
  background-color: rgba(251, 246, 246, 0.8);
  box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.16), 0 0 16px 0 rgba(51, 51, 51, 0.16), 0 0 32px 0 rgba(51, 51, 51, 0.16);
  -webkit-transition: bottom 0.6s;
  transition: bottom 0.6s;
  color: #ea592c;
  z-index: 1; }
  .actions-menu .actions.active {
    bottom: 0px; }
  .actions-menu .actions .items {
    padding: 35px 0px; }
  .actions-menu .actions .icons-col {
    margin-left: 12px;
    position: relative;
    top: 6px; }
  .actions-menu .actions .action-item .action {
    height: 35px;
    display: block; }
    .actions-menu .actions .action-item .action .action-label {
      width: 128px; }
  .actions-menu .actions .text-right {
    text-align: right; }
  .actions-menu .actions .action.disabled {
    color: rgba(0, 0, 0, 0.4);
    cursor: not-allowed; }

.action-container {
  height: 56px;
  width: 56px;
  border-radius: 16px 0 16px 0;
  background-color: #ea592c;
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.16), 0 8px 16px 0 rgba(51, 51, 51, 0.16), 0 26px 32px 0 rgba(51, 51, 51, 0.16);
  position: fixed;
  right: 15px;
  bottom: 15px;
  text-align: center; }
  .action-container .icon {
    color: #fff;
    margin: 15px; }

@media (min-width: 1280px) {
  .actions-menu .actions {
    width: calc(100% - 235px); } }

.vehicles-list .vehicle {
  border-bottom: 1px solid #f1f3f4;
  padding: 35px 0;
  cursor: pointer; }
  .vehicles-list .vehicle .main-text {
    margin-bottom: 5px; }

.view-vehicle,
.new-vehicle {
  margin-top: 85px; }

.autocomplete-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 50px;
  font-size: 14px; }
  .autocomplete-item .icon {
    display: inline-flex;
    align-items: center; }
  .autocomplete-item .sub-text {
    color: rgba(0, 0, 0, 0.4); }

.autocomplete-item.no-subtext {
  min-height: 24px; }

@media (min-width: 1280px) {
  .app .page {
    margin-top: 0px; } }

section.abc-section {
  padding: 20px 0px;
  border-bottom: 1px solid #f1f3f4;
  width: 100%;
  display: inline-block; }
  section.abc-section h2 {
    font-weight: normal;
    font-size: 20px; }

.unavailability-section .unavailability {
  padding: 20px 0; }
  .unavailability-section .unavailability .more {
    text-align: center; }
  .unavailability-section .unavailability .main-text {
    margin-bottom: 5px; }

.unavailability-section .link-button {
  color: #ea592c;
  margin: 20px 0;
  display: block; }

.drivers-list .driver {
  border-bottom: 1px solid #f1f3f4;
  padding: 35px 0;
  cursor: pointer; }
  .drivers-list .driver .main-text {
    margin-bottom: 5px; }
  .drivers-list .driver .status-icons {
    text-align: right; }

.view-driver,
.new-driver {
  margin-top: 85px; }

@media (min-width: 1280px) {
  .app .page {
    margin-top: 0px; } }

.unavailability-section .unavailability {
  padding: 20px 0; }
  .unavailability-section .unavailability .more {
    text-align: center; }
  .unavailability-section .unavailability .main-text {
    margin-bottom: 5px; }

.unavailability-section .link-button {
  color: #ea592c;
  margin: 20px 0;
  display: block; }

.lg-nav {
  height: 100%;
  background-color: #ea592c;
  color: #fff;
  font-size: 16px;
  border-radius: 0 0 16px 0;
  background-color: #ea592c;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 16px 32px 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  width: 266px;
  top: 0px;
  left: -500px;
  -webkit-transition: left 0.6s;
  transition: left 0.6s;
  z-index: 100; }
  .lg-nav.active {
    left: 0px;
    height: 100%;
    width: 266px; }
  .lg-nav h2 {
    font-weight: normal;
    margin: 0;
    font-size: 24px; }
  .lg-nav .inner {
    padding: 32px 40px; }
  .lg-nav .logo-container {
    bottom: 45px;
    position: absolute; }
    .lg-nav .logo-container img {
      width: 70%;
      margin: 0 auto;
      display: block; }
  .lg-nav .username {
    font-size: 12px;
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    color: rgba(255, 255, 255, 0.8); }
  .lg-nav ul {
    padding: 0; }
    .lg-nav ul li {
      margin: 20px 0;
      list-style: none; }
      .lg-nav ul li a {
        color: #fff;
        text-decoration: none;
        width: 100%;
        display: inline-block;
        padding: 10px 0; }
  .lg-nav .nav-footer {
    font-size: 0.6em;
    text-align: center; }

@media (min-width: 1280px) {
  .lg-nav {
    left: 0px;
    max-width: 235px; }
    .lg-nav.active {
      left: 0px;
      height: 100%; }
    .lg-nav .inner {
      padding: 48px; }
  .lg-nav li.active::before {
    content: "\2022";
    position: absolute;
    margin-left: -15px;
    padding: 10px 0; } }

@media (max-height: 670px) {
  .lg-nav .logo-container {
    display: none; } }

.notification.info div {
  background-color: #ea592c; }

.notification.error div {
  background-color: #000; }

.notification .message-content {
  margin: -1em 0; }

.notification.info div {
  background-color: #ea592c; }

.environment {
    width: 300px;
    position: fixed;
    z-index: 100;
    left: 70%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 5px;
    top: 0;
    text-align: center;
    border-radius: 0 0 10px 10px;
    font-weight: bold;
    background-color: red;
    color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.08), 0 8px 16px 0 rgba(0,0,0,0.08), 0 26px 32px 0 rgba(0,0,0,0.08);
}

