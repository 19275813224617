@import "../../colors.scss";

.schedule {
    .icon-action {
        cursor: pointer;
    }

    .inline-block {
        display: inline-block;
    }

    .schedule-header {
        margin-left: 0;
        position: fixed;
        top: 84px;
        width: 100%;
        z-index: 2;

        .schedule-date-select {
            width: 100%;
            background: $dirtier-pink;
            line-height: 40px;
            padding-top: 20px;
            white-space: nowrap;
            ul {
                li {
                    a {
                        font-size: 24px;
                    }
                }
            }
        }

        .schedule-version-select {
            background: $dirtier-pink;
            height: 40px;
        }

        .date-nav {
            width: 100%;
            background: $dirtier-pink;
            top: 124px;
            height: 29px;
            line-height: 40px;
            white-space: nowrap;
        }

        ul {
            padding: 0;
            margin: 0;
            line-height: 40px;
            li {
                display: inline-block;
                margin-right: 20px;
                line-height: 20px;
                a {
                    font-size: 16px;
                    font-weight: 300;
                    color: $light-grey;
                    text-decoration: none;
                    cursor: pointer;
                    &.active {
                    color: $abc-orange;
                    }
                }
            }
        }

        .date-container {
            line-height: 40px;
            min-width: 250px;
        }
    
        .date-input {
            margin-top:-5px;
            width: 250px;
        }
    }

    .section-header {
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: #f5efef;
        font-family: Roboto;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        padding: 30px 0;
    }

    .no-schedule,
    .live {
        width: 100%;
        margin-top: 225px;
    }

    .no-schedule-header{
        width: 790px;
        height: 24px;
        font-family: Roboto;
        font-size: 24px;
        color: #333333;
        text-decoration: none solid rgb(51, 51, 51);
        line-height: 24px;
        text-align: center;
        margin-bottom: 24px;
        }

    .no-schedule-content{
        width: 518px;
        height: 48px;
        font-family: Roboto;
        font-size: 20px;
        color: $dark-grey;
        font-weight: 300;
        text-decoration: none solid rgb(51, 51, 51);
        line-height: 24px;
        text-align: center;
    }

    .center {
        text-align: center;
    }

    .no-schedule-image {
        width: 400px;
        height: 400px;
    }

    .run-container.dual {
        right: 240px;
    }

    .run-container {
        width: 219px;
        height: 56px;
        border-top-left-radius: 16px;
        background-color: $abc-orange;
        background-size: cover;
        box-shadow: 0px 8px 16px rgba(51,51,51,0.08), 0px 16px 32px rgba(51,51,51,0.16);
        position: fixed;
        right: 15px;
        bottom: 0px;
        text-align: center;
        align-items: center;
        cursor: pointer;
    }
    
    .run-text {
        width: 148px;
        height: 24px;
        font-family: Roboto;
        font-size: 18px;
        color: $white;
        text-decoration: none solid rgb(255, 255, 255);
        line-height: 24px;
        text-transform: uppercase;
        padding-left: 31px;
    }

    .run-icon {
        width: 24px;
        height: 24px;
        fill: #fcfcfc;
        padding: 16px;
    }

    .badge span {
        top: 50%;
        background-color: $abc-orange;
    }

    .badge {
        padding-right: 16px;
        vertical-align: baseline;
    }

    .alert-text {
        width: 100%;
        background: $abc-orange;
        position: fixed;
        top: 154px;
        z-index: 2;
        height: 40px;
        line-height: 40px;
        text-align:center;
        white-space: nowrap;
        color:white;
        vertical-align: center;
        text-align: center;
    }

    .alert-text + .schedule-container {
        margin-top: 254px;
    }

    .schedule-container {
        margin-top: 214px;
    }

    .active input{
        cursor: pointer;
    }
    
    .disabled-li {
        cursor: not-allowed;
    }
}

.change-list,
.schedule-list,
.available-vehicles,
.unassigned-driver-list {
    width: 100%;
    
    .headers,
    .detail {
        display: inline-block;
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .headers {
        color: $light-grey;
        font-size: 14px;
        line-height: 24px;
    }

    .headers.warning {
        color: $abc-orange;
    }

    .detail {
        color: $dark-grey;
        font-size: 16px;
    }
}

.available-vehicles {
    .available-vehicle {
        border-bottom: 1px solid $dividing-grey;
        padding: 30px 0;
    }

    .section {
        width: 100%;
        &.action-strip {
            text-align: center;
            padding: 10px;
            border-bottom: 1px solid $dividing-grey;
        }
    }
}

.change-list,
.unassigned-driver-list {
    .item {
        border-bottom: 1px solid $dividing-grey;
        padding: 15px 0;
    }
}

.schedule-list {
    .detail.address.end {
        color: $red; 
    }

    .detail.address.start {
        color: $green; 
    }

    .detail.warning {
        color: $red;
        font-weight: 500
    }

    .outside-window .etd, .outside-window .time {
        color: $red;
    }
    
    .past-etd .etd {
        color: $abc-orange;
    }

    .past-eta .time {
        color: $light-grey;
    }

    .next-eta .time {
        color: $green;
        font-weight: bold;
    }

    .action.disabled {
        color: $light-grey;
    }

    .icon-container {
        text-align: center;
    }

    .icon-coloured {
        color: $abc-orange;
    }

    .rego-container {
        padding-right: 12px;
    }

    .route-header {
        color: $abc-orange;
        font-weight: bold;
    }

    .route-header svg {
        vertical-align: middle;
    }

    .name-container.paused {
        color: $warning-red;
    }

    .paused .route-header {
        color: $red;
    }

    .schedule-list-item {
        border-bottom: 1px solid $dividing-grey;
        padding: 30px 0;
    }
    
    .section {
        width: 100%;
        &.action-strip {
            text-align: center;
            padding: 10px;
            border-bottom: 1px solid $dividing-grey;
        }
    }

    .name-container {
        min-width: 800px;
        width: 84%;
        height: 24px;
        font-family: Roboto;
        font-size: 18px;
        color: #333333;
        font-weight: 300;
        text-decoration: none solid rgb(51, 51, 51);
        line-height: 24px;
        margin-left: 16px;
        padding: 40px 0;
        margin-bottom: 20px;
    }
    
    .taskWrapper {
        margin-left: 15px;
        border-left: 5px transparent;
    }

    .taskWrapper.changed-new {
        border-left: solid $changed-new;
    }

    .taskWrapper.changed-moved {
        border-left: solid $changed-moved;
    }
    
    .task {
        min-width: 800px;
        width: 96%;
        height: 108px;
        border: 1px solid #f5efef;
        border-radius: 16px;
        background-color: $white;
        background-size: cover;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.02), 0px 4px 8px rgba(0,0,0,0.04);
        padding: 40px 0;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 8%;

        .align-center {
            text-align: center;
        }

        .move-task-button {
            background-color: $abc-orange;
            border-radius: 8px;
            color: $white;
            margin-left: 24px;
            padding: 16px;
        }

        .no-pad {
            padding-top: 0;
        }
        
        .status-icons {
            text-align: right;
        }

        .task-type {
            color: $abc-orange;
        }
    }
    
    .task.firstup-task, .task.asap-task {
        background-color: $highlight-green;
    }
    
    .task.cancelled-task {
        background: $lighter-grey;
        
        .detail {
            text-decoration: line-through;
            span {
                text-decoration: line-through;
            }
        }

        .detail.status-text {
            text-decoration: none;
        }
    }
    
    .task.draggable {
        background: repeating-linear-gradient(
            45deg,
            $dirty-pink,
            $dirty-pink 10px,
            $dirtier-pink 10px,
            $dirtier-pink 20px
        );
    }

    .task.dragging {
        opacity: 0;
    }

    .task.invalid-task {
        background: repeating-linear-gradient(
            45deg,
            $warning-red,
            $warning-red 10px,
            $trans-white 10px,
            $trans-white 20px
        );
    }
    .task.complete-task {
        background: $dividing-grey;
        height: auto;
        padding: 10px 0;
        .done {
            text-align: center;
            color: $abc-orange;
        }
        .headers {
            display: none;
        }
        .detail {
            color: $light-grey;
            div {
                align-self: center;
            }
            span {
                vertical-align: middle;
            }
        }
    }

    .taskWrapper.changed-removed {
        border-left: solid $changed-removed;
    }

    .removed-message {
        min-width: 800px;
        width: 96%;
        border: 1px solid #f5efef;
        border-radius: 16px;
        background-color: $white;
        background-size: cover;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.02), 0px 4px 8px rgba(0,0,0,0.04);
        padding: 10px 0;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-right: 8%;
    }
}

.live-schedule {
    .schedule-list {
        .task.late-task {
            background-color: $warning-red;
        }
    }
}

.draft-schedule {
    .job.firstup-job, .job.asap-job {
        background-color: $highlight-green;
    }
}

.highlight {
    color: $abc-orange;

    span {
        background-color: $abc-orange;
        color: $white;
    }
} 

.move-task-dialog {
    background-color: #ffffff;
    background-size: cover;
    border-radius: 16px;
    box-shadow: 0px 8px 16px rgba(51,51,51,0.08), 0px 16px 32px rgba(51,51,51,0.16);
    padding: 20px 30px;
    position: relative;
    text-align: left;
    left: 72px;
    top: -60px;
    width: 160px;
    z-index: 50;

    .action {
        margin-top: 10px;
        color: $light-grey;
    }

    .action svg {
        vertical-align: middle;
    }
}

.abc-return-dialog {
    .radio-wrapper {
        margin-bottom: 30px;
    }
}

.schedule-to-route-dialog {
    .checkbox-control {
        padding-top: 1.5em;
    }
    .checkbox-icon {
        fill: $abc-orange;
        padding-right: 0.5em;
    }
}

.unassign-live-job-dialog {
    .unassign-note {
        color: rgba(0,0,0,0.4);
    }
}

@media (min-width: 1280px) {
    .filter-state .chips {
        margin-left: -22px;
    }

    .schedule {
        .schedule-header {
            margin-left: -20px;
            top: 0px;
        }

        .alert-text + .schedule-container {
            margin-top: 170px;
        }

        .schedule-container {
            margin-top: 130px;
        }
    }
}
