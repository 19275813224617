@import '../../colors.scss';

.lg-nav {
  height: 100%;
  background-color: $abc-orange;
  color: #fff;
  font-size: 16px;
  border-radius: 0 0 16px 0;
  background-color: $abc-orange;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 16px 32px 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  width: 266px;
  top: 0px;
  left: -500px;
  transition: left 0.6s;
  z-index: 100;

  &.active {
    left: 0px;
    height: 100%;
    width: 266px;
  }

  h2 {
    font-weight: normal;
    margin: 0;
    font-size: 24px;
  }

  .inner {
    padding: 32px 40px;
  }

  .logo-container {
    bottom: 45px;
    position: absolute;
    img {
      width: 70%;
      margin: 0 auto;
      display: block;
    }
  }

  .username {
    font-size: 12px;
    margin-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
  }

  ul {
    padding: 0;
    li {
      margin: 20px 0;
      list-style: none;
      a {
        color: #fff;
        text-decoration: none;
        width: 100%;
        display: inline-block;
        padding: 10px 0;
      }
    }
  }

  .nav-footer {
     font-size: 0.6em;
     text-align: center;
  }
}

@media (min-width: 1280px) {
  .lg-nav {
    left: 0px;
    max-width: 235px;

    &.active {
      left: 0px;
      height: 100%;
    }

    .inner {
      padding: 48px;
    }
  }

  .lg-nav li.active::before {
    content: "\2022";
    position: absolute;
    margin-left: -15px;
    padding: 10px 0;
  }
}

@media (max-height: 670px) {
  .lg-nav .logo-container {
    display: none;
  }
}
