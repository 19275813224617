@import "../../colors.scss";

.dialog {
  height: 100%;
  border-radius: 0px;
  background-color: $white;
  border-radius: 16px 0px 0px;
  transition: right 0.6s;
  z-index: 101;
  position: fixed;
  top: 0px;
  right: -500px;

  .content {
    text-align: left;
    margin-top: 30px;
    line-height: 24px;
  }

  .head {
    padding: 32px 0;
    text-align: left;
    border-bottom: 1px solid $dividing-grey;

    h2 {
      display: inline-block;
      margin: 0;
      font-size: 24px;
      color: $dark-grey;
      line-height: 24px;
      font-weight: normal;
      text-align: left;
    }
  }

  .arrow-container .arrow {
    display: block;
    margin: 32px auto;
  }

  &.slide {
    height: 100%;
    width: 0px;
    background-color: $white;
    transition: right 0.6s;
    z-index: 100;
    position: fixed;
    top: 0px;
    right: -500px;
  }

  &.full-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 101;
    background-color: $solid-dirty-pink;
    top: 0;
    left: 0;
    overflow-y: scroll;
    border-radius: 0px;

    .content,
    .head,
    .actions {
      max-width: 480px;
      margin: 0 auto;
    }

    .arrow-container .arrow {
      display: none;
    }
  }

  &.active {
    right: 0px;
    width: 100%;
  }

  .actions {
    color: $light-grey;
    padding-top: 50px;
    text-align: right;

    div {
      margin-left: 20px;
      display: inline-flex;
      vertical-align: middle;
      align-items: center;
    }

    .icon svg {
      vertical-align: middle;
    }
  }
}

@media (min-width: 1280px) {
  .dialog {
    box-shadow: $shadow 0px 0px 16px 0px;

    &.slide.active {
      right: 0px;
      width: 500px;
    }

    &.slide .arrow-container .arrow {
      display: none;
    }
  }
}
